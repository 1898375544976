/* Main Page Styling */
.tables-page-container {
    padding: 30px;
    background-color: var(--card-background);
    max-width: 1200px;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    color: var(--text-color);
}

/* Title Styling */
.tables-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--text-color);
    text-align: center;
    margin-bottom: 30px;
}

/* Ad Container */
.ad-container {
    width: 100%;
    min-height: 120px;
    margin: 30px 0;
    text-align: center;
    display: block;
}

@media (min-width: 769px) {
    .ad-container {
        min-height: 250px;
        max-width: 970px;
        margin: 40px auto;
    }
}

/* Search and Sort Controls */
.search-sort-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Search Input */
.search-input {
    width: 60%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--input-background);
    color: var(--text-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    margin-bottom: 15px;
}

.search-input:focus {
    outline: none;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
}

/* Sort Dropdown */
.sort-select {
    width: 60%;
    padding: 12px;
    font-size: 1rem;
    border-radius: 8px;
    background-color: var(--input-background);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}

.sort-select:focus {
    outline: none;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
}

/* Game Grid Layout */
.game-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    align-items: start;
}

/* Game Card Styling */
.game-card {
    background-color: var(--card-background);
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease, height 0.3s ease;
    cursor: pointer;
    text-align: center;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.game-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.15);
}

.game-image {
    width: 100%;
    height: 130px;
    object-fit: contain;
    background-color: var(--card-background);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* Game Details Section */
.game-details {
    padding: 10px;
    flex-grow: 1;
}

.game-name {
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

/* NSFW Filter Toggle */
.nsfw-toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

/* Label Text */
.toggle-text {
    font-size: 1.25rem;
    margin-top: 5px;
    color: var(--text-color);
    margin-bottom: 8px;
}

/* Toggle Switch Wrapper */
.toggle-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
}

/* Hide the default checkbox */
.toggle-input {
    display: none;
}

/* Steam Auth Section */
.steam-auth-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    flex-direction: column;
}

/* Steam Login Button */
.steam-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #1b2838;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 100%;
    max-width: 250px;
    text-align: center;
    margin-top: 10px;
}

.steam-login-button:hover {
    background-color: #2a475e;
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.steam-login-button:active {
    transform: translateY(1px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

/* Steam Logo */
.steam-logo {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    display: inline-block;
}

/* Steam User Info */
.steam-user-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.steam-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #2a475e;
}

.logout-button {
    padding: 8px 16px;
    font-size: 0.9rem;
    font-weight: bold;
    color: white;
    background-color: #cc3333;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.logout-button:hover {
    background-color: #ff4444;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.logout-button:active {
    transform: translateY(1px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

/* Pagination Container */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 8px;
}

/* Pagination Buttons */
.pagination button {
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    padding: 8px 14px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover Effect */
.pagination button:hover {
    background-color: var(--hover-background);
    transform: scale(1.05);
}

/* Active Page Button */
.pagination button.active {
    background-color: var(--success-color);
    color: var(--button-text);
    transform: scale(1.1);
}

/* Disabled Button */
.pagination button:disabled {
    background-color: var(--border-color);
    color: var(--text-color-secondary);
    cursor: not-allowed;
    transform: none;
}

/* Dark Mode Overrides */
body.dark .pagination button {
    background-color: var(--button-background);
    color: var(--button-text);
}

body.dark .pagination button:hover {
    background-color: var(--hover-background);
}

body.dark .pagination button.active {
    background-color: var(--success-color);
}

body.dark .pagination button:disabled {
    background-color: var(--border-color);
    color: var(--text-color-secondary);
}

/* Responsive Design */
@media (max-width: 768px) {
    .game-grid {
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
}

@media (max-width: 480px) {
    .game-grid {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .tables-page-container {
        padding: 15px;
    }

    .tables-title {
        font-size: 2rem;
    }

    .game-name {
        font-size: 1.1rem;
    }

    .download-link {
        font-size: 0.9rem;
        padding: 8px 16px;
    }

    .search-input {
        width: 90%;
        margin-bottom: 15px;
    }

    .sort-select {
        width: 90%;
    }
    .steam-login-button {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    .steam-logo {
        width: 20px;
        height: 20px;
    }

    .steam-avatar {
        width: 32px;
        height: 32px;
    }
}