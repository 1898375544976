/* Root Variables for Theme Consistency */
:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --success-color: #28a745;
  --danger-color: #dc3545;
  --warning-color: #ffc107;
  --text-color: #333;
  --text-color-secondary: #666;
  --background-color: #f8f9fa;
  --card-background: #fff;
  --button-background: #007bff;
  --button-text: #fff;
  --button-hover: #0056b3;
  --border-color: #dee2e6;
  --input-background: #fff;
  --input-text: #333;
  --navbar-height: 70px; /* Added for dynamic navbar offset */
}

/* Ensure body controls scrolling */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Page Container */
.page-container {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Main Container */
.category-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: none;
  width: 100%;
}

/* Sidebar Ads */
.ad-sidebar {
  position: fixed;
  top: var(--navbar-height); /* Offset by navbar height */
  width: 160px;
  height: 600px;
  display: none;
  z-index: 900; /* Below navbar */
}

.ad-sidebar-left {
  left: calc(50% - 600px - 160px - 20px); /* Works fine */
}

.ad-sidebar-right {
  right: calc(50% - 600px - 160px - 20px); /* Should mirror left, but let’s debug */
  /* Temporary test: right: 20px; */ /* Uncomment to test visibility */
}

.ad-sidebar ins {
  width: 100%;
  height: 100%;
  display: block;
}

/* Ad Containers (Top and Bottom) */
.ad-container {
  width: 100%;
  min-height: 120px;
  margin: 30px 0;
  text-align: center;
  display: block;
}

.ad-container-top {
  margin-top: 0;
}

.ad-container-bottom {
  margin-bottom: 0;
}

/* Responsive Design */
@media (min-width: 1520px) {
  .ad-sidebar {
    display: block; /* Both should show */
  }

  .category-detail-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .ad-container {
    min-height: 250px;
    max-width: 970px;
    margin: 40px auto;
  }
}

@media (max-width: 1519px) and (min-width: 769px) {
  .category-detail-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .ad-container {
    min-height: 250px;
    max-width: 970px;
    margin: 40px auto;
  }

  .ad-sidebar {
    display: none;
  }
}

@media (max-width: 768px) {
  .category-detail-container {
    padding: 20px 10px;
    max-width: 100%;
  }

  .ad-container {
    min-height: 100px;
    margin: 20px 0;
  }

  .ad-sidebar {
    display: none;
  }
}

/* Banner */
.banner {
  margin-bottom: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  position: relative;
  z-index: 1;
  text-align: center;
}

/* Discontinued Message */
.discontinued-message {
  text-align: center;
  color: var(--danger-color);
  font-weight: 500;
  margin-bottom: 25px;
}

/* Alerts */
.alert {
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 25px;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.alert-icon {
  margin-right: 10px;
  font-size: 1.2rem;
}

.alert-message {
  flex-grow: 1;
}

.alert-close {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: inherit;
}

/* Divider */
.divider {
  border: none;
  border-top: 1px solid var(--border-color);
  margin: 20px 0;
}

/* Content Layout */
.category-content {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  padding: 20px 0;
}

/* Media Section */
.category-media {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin-bottom: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.image-slideshow {
  position: relative;
  width: 100%;
  height: 100%;
}

.category-screenshot {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: block;
}

.category-screenshot:hover {
  transform: scale(1.02);
}

.prev-btn, .next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: 10;
}

.prev-btn:hover, .next-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.expanded-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.expanded-image-container {
  position: relative;
}

.expanded-image {
  max-width: 90%;
  max-height: 90vh;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

/* YouTube Icon Overlay */
.youtube-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.youtube-icon {
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.youtube-icon:hover {
  transform: scale(1.1);
}

/* Status Badge */
.status-badge-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 6px 12px;
  font-size: 0.9rem;
  font-weight: 600;
  color: white;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  z-index: 20;
}

.status-badge-overlay.green {
  background-color: var(--success-color);
}

.status-badge-overlay.yellow {
  background-color: var(--warning-color);
}

.status-badge-overlay.red {
  background-color: var(--danger-color);
}

.status-badge-icon {
  margin-right: 6px;
  font-size: 1rem;
}

/* Download Section */
.download-section {
  width: 100%;
  text-align: center;
}

.download-section h3 {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--text-color);
  text-transform: uppercase;
}

/* Updated Table Item Layout */
.table-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: var(--card-background);
  border-radius: 6px;
  margin-bottom: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
}

.table-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Ensure table header items stay on one line */
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.table-meta {
  display: flex;
  gap: 15px;
  flex-wrap: nowrap;
  overflow: hidden;
  white-space: nowrap;
}

.table-meta span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Download link styling */
.download-link {
  background-color: var(--button-background);
  color: var(--button-text);
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.download-link:hover {
  background-color: var(--button-hover);
}

/* Description below header */
.table-description {
  font-size: 0.95rem;
  line-height: 1.5;
  color: var(--text-color-secondary);
  margin-top: 10px;
}

/* Steam Link Container */
.steam-link-container {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.steam-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.steam-icon:hover {
  transform: scale(1.1);
}

.steam-link-container::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.85rem;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.steam-link-container:hover::after {
  opacity: 1;
}

/* Description Section */
.category-description {
  flex: 1;
  min-width: 300px;
  padding: 20px 0;
}

.header-actions {
  margin-bottom: 20px;
}

.notification-section {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
}

.notification-toggle, .save-latest-button-container {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.notification-icon, .save-latest-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.notification-icon:hover:not(.disabled), .save-latest-icon:hover:not(.disabled) {
  transform: scale(1.1);
}

.notification-icon.disabled, .save-latest-icon.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.notification-toggle::after, .save-latest-button-container::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.85rem;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.notification-toggle:hover::after, .save-latest-button-container:hover::after {
  opacity: 1;
}

.report-button {
  background-color: var(--danger-color);
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.report-button:hover {
  background-color: #c82333;
}

.report-form {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.report-textbox {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  resize: none;
  font-size: 1rem;
  background-color: var(--input-background);
  color: var(--input-text);
}

.report-textbox:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.report-submit-button {
  background-color: var(--button-background);
  color: var(--button-text);
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.report-submit-button:hover {
  background-color: var(--button-hover);
}

.report-submit-button:disabled {
  background-color: var(--secondary-color);
  cursor: not-allowed;
}

.description-content {
  margin-bottom: 20px;
}

.description-content p {
  line-height: 1.6;
  color: var(--text-color);
}

.steam-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
}

.steam-link:hover {
  text-decoration: underline;
}

/* Steam Description */
.steam-description {
  margin-top: 15px;
  padding: 15px;
  background-color: var(--card-background);
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  line-height: 1.6;
  color: var(--text-color);
  font-size: 0.95rem;
  overflow: hidden;
  word-wrap: break-word;
}

.steam-description img,
.steam-description iframe,
.steam-description video {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 10px 0;
  border-radius: 4px;
}

.steam-description p {
  margin: 0 0 10px 0;
  max-width: 100%;
}

.steam-description a {
  color: var(--primary-color);
  text-decoration: none;
}

.steam-description a:hover {
  text-decoration: underline;
}

/* Disclaimer */
.disclaimer {
  margin-top: 40px;
  padding: 20px;
  background-color: rgba(255, 77, 77, 0.05);
  border-radius: 6px;
  text-align: center;
  color: var(--text-color-secondary);
  font-size: 0.9rem;
}