/* Panel Wrapper */
.moderator-panel {
  padding: 30px;
  background: linear-gradient(135deg, var(--card-background), var(--section-background));
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  overflow-x: auto;
  box-sizing: border-box;
  width: 100%;
}

/* Ensure inner content respects container boundaries */
.moderator-panel > div {
  width: 100%;
  box-sizing: border-box;
}

.moderator-panel-title {
  font-size: 32px;
  margin-bottom: 30px;
  text-align: center;
  color: var(--text-color);
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Section Header Buttons */
.moderator-panel-header {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.moderator-panel-header button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(to right, var(--button-background), var(--hover-background));
  color: var(--button-text);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.moderator-panel-header button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.moderator-panel-header button.active {
  background: var(--hover-background);
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

/* Users Section Controls */
.users-controls {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  background: rgba(255, 255, 255, 0.05);
  padding: 15px;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 100%;
}

.user-counts {
  display: flex;
  gap: 20px;
  font-size: 15px;
  flex-wrap: wrap;
  align-items: center;
}

.user-counts span {
  background: var(--section-header-bg);
  padding: 8px 14px;
  border-radius: 20px;
  color: var(--text-color);
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  white-space: nowrap;
}

.user-counts span:hover {
  transform: scale(1.05);
}

.search-input-mod {
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  background: var(--section-background);
  color: var(--text-color);
  font-size: 14px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  flex: 1;
  min-width: 200px;
  max-width: 400px;
  box-sizing: border-box;
  margin-right: 30px;
}

.search-input-mod:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.role-filter {
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  background: var(--section-background);
  color: var(--text-color);
  font-size: 14px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  min-width: 150px;
  box-sizing: border-box;
}

.role-filter:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

/* Table Styling */
.moderator-panel table {
  width: 100%;
  max-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: var(--section-background);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  table-layout: auto;
}

.moderator-panel table thead {
  background: var(--section-header-bg);
  position: sticky;
  top: 0;
  z-index: 1;
}

.moderator-panel table thead th {
  padding: 15px 20px;
  text-align: left;
  color: var(--text-color-secondary);
  font-weight: 600;
  border-bottom: 2px solid var(--border-color);
  white-space: nowrap;
}

.moderator-panel table tbody tr {
  transition: all 0.3s ease;
}

.moderator-panel table tbody tr:hover {
  background: var(--hover-background);
}

.moderator-panel table tbody td {
  padding: 15px 20px;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-color);
  word-wrap: break-word;
}

/* Ban Details Button */
.ban-details-btn {
  background: linear-gradient(to right, #ff9800, #fb8c00);
  color: var(--button-text);
  border: none;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.ban-details-btn:hover {
  background: #fb8c00;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Toggle Buttons for IP History and Reports */
.toggle-ip-history-btn,
.toggle-reports-btn {
  background: linear-gradient(to right, var(--button-background), var(--hover-background));
  color: var(--button-text);
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.toggle-ip-history-btn:hover,
.toggle-reports-btn:hover {
  background: var(--hover-background);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Action Buttons in Tables */
.action-buttons {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping if needed */
}

.moderator-panel table tbody td button {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.moderator-panel table tbody td button.ban {
  background: var(--error-color);
  color: var(--button-text);
}

.moderator-panel table tbody td button.ban:hover {
  background: #c53030;
  transform: translateY(-2px);
}

.moderator-panel table tbody td button.unban {
  background: var(--success-color);
  color: var(--button-text);
}

.moderator-panel table tbody td button.unban:hover {
  background: #2f855a;
  transform: translateY(-2px);
}

.moderator-panel table tbody td button.verify {
  background: var(--button-background);
  color: var(--button-text);
}

.moderator-panel table tbody td button.verify:hover {
  background: var(--hover-background);
  transform: translateY(-2px);
}

/* Expandable Section for IP History and Reports */
.expandable-section {
  padding: 15px 20px;
  background: var(--card-background);
  border-top: 1px solid var(--border-color);
}

/* IP History Section */
.ip-history {
  margin-top: 12px;
  background: var(--card-background);
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ip-history h5 {
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--text-color);
  font-weight: 600;
}

.ip-history ul {
  margin: 0;
  padding-left: 20px;
  list-style: disc;
}

.ip-history li {
  margin-bottom: 4px;
  font-size: 13px;
  color: var(--text-color);
}

/* Reports List Section */
.reports-list {
  margin-top: 12px;
  background: var(--card-background);
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reports-list h5 {
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--text-color);
  font-weight: 600;
}

.reports-list ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.reports-list li {
  background: var(--input-background);
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  transition: transform 0.2s ease;
}

.reports-list li:hover {
  transform: translateX(5px);
}

.reports-list li p {
  margin: 5px 0;
  font-size: 13px;
  color: var(--text-color);
}

.reports-list li strong {
  font-weight: bold;
  margin-right: 5px;
}

.reports-list p {
  margin: 0;
  font-size: 13px;
  color: var(--text-color);
  font-style: italic;
}

/* Reports Section */
.report-item {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  background: var(--section-background);
  box-sizing: border-box;
}

.report-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: var(--section-header-bg);
  cursor: pointer;
  transition: all 0.3s ease;
}

.report-summary:hover {
  background: var(--hover-background);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.report-summary span {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-color);
}

.expand-button {
  background: var(--button-background);
  color: var(--button-text);
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.expand-button:hover {
  background: var(--hover-background);
  transform: translateY(-2px);
}

/* Expanded Report Details */
.report-details {
  padding: 20px;
  background: var(--section-background);
  max-width: 100%;
}

.report-details table {
  width: 100%;
  border-collapse: collapse;
}

.report-details th,
.report-details td {
  padding: 12px 15px;
  border-bottom: 1px solid var(--border-color);
  word-wrap: break-word;
}

.report-details th {
  text-align: left;
  background: var(--section-header-bg);
  color: var(--text-color-secondary);
  font-weight: 600;
}

.report-details tr:last-child td {
  border-bottom: none;
}

/* Modal Overlay (Matching Admin Panel) */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  animation: fadeIn 0.3s ease;
}

/* Modal Content (Matching Admin Panel) */
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background-color: var(--card-background);
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: var(--text-color);
}

.modal-content h3 {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--link-color);
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.modal-content p {
  font-size: 1.2rem;
  margin-bottom: 25px;
  color: var(--text-color);
  line-height: 1.5;
}

.modal-content .profile-link {
  color: var(--link-color);
  text-decoration: none;
  font-weight: bold;
}

.modal-content .profile-link:hover {
  color: var(--hover-background);
  text-decoration: underline;
}

/* Modal Button Container (Matching Admin Panel) */
.modal-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  width: 100%;
}

/* Close Button (Matching Admin Panel’s .cancel-button) */
.close-btn {
  width: 45%;
  padding: 12px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1rem;
  margin: 10px 5px;
  box-sizing: border-box;
  background-color: #e74c3c;
  color: var(--button-text);
  border: none;
}

.close-btn:hover {
  background-color: #c0392b;
  transform: scale(1.03);
}

/* Utility Classes */
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-success { color: var(--success-color); }
.text-error { color: var(--error-color); }

/* N/A Indicator */
.na-indicator {
  color: var(--error-color);
  font-weight: bold;
  font-size: 18px;
}

/* Status Indicators */
.status-verified,
.status-not-verified,
.status-banned,
.status-not-banned {
  font-weight: bold;
  font-size: 18px;
}

.status-verified { color: var(--success-color); }
.status-not-verified { color: var(--error-color); }
.status-banned { color: var(--error-color); }
.status-not-banned { color: var(--success-color); }

/* Icons */
.status-icon {
  margin: 0;
  font-size: 1.5em;
}

.status-icon.green { color: var(--success-color); }
.status-icon.yellow { color: var(--warning-color, #f1c40f); }
.status-icon.red { color: var(--error-color); }
.status-icon.clear { font-size: 16px; }

/* Button Styling */
.status-button {
  padding: 10px 18px;
  margin-right: 10px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.status-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.status-button.green {
  background: linear-gradient(to right, var(--success-color, #28a745), #48bb78);
}

.status-button.green:hover { background: #2f855a; }

.status-button.yellow {
  background: linear-gradient(to right, var(--warning-color, #f1c40f), #f6e05e);
  color: #333;
}

.status-button.yellow:hover { background: #d69e2e; }

.status-button.red {
  background: linear-gradient(to right, var(--error-color, #dc3545), #f56565);
}

.status-button.red:hover { background: #c53030; }

.status-button.clear {
  background: linear-gradient(to right, var(--clear-color, #6c757d), #a0aec0);
  color: white;
}

.status-button.clear:hover { background: #5a6268; }

/* Responsive Design */
@media (max-width: 768px) {
  .moderator-panel {
    padding: 20px;
  }

  .moderator-panel-title {
    font-size: 24px;
  }

  .moderator-panel-header button {
    font-size: 14px;
    padding: 10px 18px;
  }

  .users-controls {
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
    gap: 15px;
  }

  .user-counts {
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .search-input-mod {
    max-width: 100%;
    min-width: 0;
    margin-right: 0;
  }

  .role-filter {
    max-width: 100%;
  }

  .moderator-panel table thead th,
  .moderator-panel table tbody td {
    padding: 10px 12px;
    font-size: 14px;
  }

  .ban-details-btn,
  .toggle-ip-history-btn,
  .toggle-reports-btn {
    padding: 4px 8px;
    font-size: 12px;
    margin-left: 5px;
  }

  .action-buttons {
    flex-direction: column;
    gap: 8px;
  }

  .ip-history,
  .reports-list {
    padding: 10px;
  }

  .ip-history h5,
  .reports-list h5 {
    font-size: 12px;
  }

  .ip-history li,
  .reports-list li p {
    font-size: 11px;
  }

  .report-summary {
    padding: 15px;
    flex-wrap: wrap;
    gap: 10px;
  }

  .report-summary span {
    font-size: 16px;
  }

  .expand-button {
    padding: 8px 12px;
    font-size: 14px;
  }

  .modal {
    padding: 10px;
  }

  .modal-content {
    max-width: 90%;
    padding: 15px;
  }

  .modal-content h3 {
    font-size: 1.2rem;
  }

  .modal-content p {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }

  .close-btn {
    width: 100%;
    padding: 10px;
    font-size: 0.9rem;
    margin: 5px 0;
  }
}

/* Animation for Modal (Matching Admin Panel) */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}