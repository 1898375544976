/* DiscordButton.css */
.discord-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px; /* Offset from the right edge when open */
    display: flex;
    align-items: center;
    z-index: 1000; /* Ensures it stays on top */
}

.discord-button-wrapper {
    display: flex;
    align-items: center;
    position: relative; /* Ensure toggle button positioning is relative to this */
    transition: transform 0.3s ease-in-out; /* Smooth sliding transition */
}

/* Adjust wrapper position when closed */
.discord-button-wrapper.closed {
    transform: translateX(calc(100% + 20px)); /* Slide fully off-screen */
}

.discord-button {
    background-color: #7289DA;
    color: white;
    border-radius: 50%;
    padding: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.discord-button:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

/* Toggle button styles */
.toggle-button {
    background-color: #7289DA;
    color: white;
    border-radius: 50%;
    padding: 5px;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
    position: absolute;
    left: -12px; /* Overlap with the Discord button when open */
    z-index: 1001; /* Ensure toggle button is above the Discord button */
}

.toggle-button:hover {
    transform: scale(1.1);
}

/* Adjust toggle button position when closed */
.discord-button-wrapper.closed .toggle-button {
    left: calc(-20px - 17px); /* Position outside the scrollbar */
}

/* Tooltip styles */
.tooltip {
    visibility: hidden;
    opacity: 0;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    position: absolute;
    bottom: 70px; /* Adjusts the distance above the button */
    transition: opacity 0.3s ease, visibility 0.3s ease;
    white-space: nowrap;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    left: 50%; /* Center the tooltip */
    transform: translateX(-50%); /* Adjust for centering */
}

/* Adjust tooltip position when closed (though it won't be visible) */
.discord-button-wrapper.closed .tooltip {
    left: calc(-28px - 17px); /* Align with the toggle button when closed */
    transform: none; /* Remove centering transform */
}

/* Show tooltip on hover only when open */
.discord-button-wrapper.open:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

/* Open state (button fully visible) */
.discord-button-wrapper.open {
    transform: translateX(0);
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .discord-button-container {
        right: 10px; /* Slightly smaller offset on mobile */
    }

    .discord-button-wrapper.closed {
        transform: translateX(calc(100% + 10px)); /* Adjust for mobile */
    }

    .discord-button-wrapper.closed .toggle-button {
        left: calc(-10px - 17px); /* Adjust for mobile */
    }

    .discord-button-wrapper.closed .tooltip {
        left: calc(-18px - 17px); /* Adjust tooltip position for mobile when closed */
    }

    .tooltip {
        bottom: 60px; /* Adjust tooltip position */
    }
}