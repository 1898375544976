/* Light Mode Variables */
:root {
  --background-color: #dde1e9;
  --text-color: #000000;
  --card-background: #ffffff;
  --section-background: #f9f9f9;
  --input-background: #f0f0f0;
  --input-text: #333333;
  --button-background: #3498db;
  --button-text: #ffffff;
  --link-color: #3498db;
  --hover-background: #2980b9;
  --success-color: #28a745;
  --error-color: #ff4d4d;
  --border-color: #ddd;
  --section-header-bg: #e0e0e0;
  --text-color-secondary: #555555;
  --dropdown-background: #ffffff;
  --dropdown-text-color: #000000;
  --dropdown-hover-background: #f0f0f0; /* Lighter hover background */
}

/* Dark Mode Variables */
body.dark {
  --background-color: #121212;
  --text-color: #e0e0e0; /* Lightened text color */
  --card-background: #1e1e1e;
  --section-background: #2a2a2a;
  --input-background: #333333;
  --input-text: #f5f5f5; /* Brighter input text color */
  --button-background: #3498db;
  --button-text: #f1f1f1;
  --link-color: #4db8ff; /* Lightened link color */
  --hover-background: #555555;
  --success-color: #28a745;
  --error-color: #ff4d4d;
  --border-color: #444444;
  --section-header-bg: #333333;
  --text-color-secondary: #cccccc; /* Lighter secondary text color */
  --dropdown-background: #333333; /* Match input background */
  --dropdown-text-color: #ffffff; /* Bright text for dark mode */
  --dropdown-hover-background: #444444; /* Slightly lighter for hover */
}

/* Ensure body and html take full height of viewport */
body, html {
  height: 100%; /* Makes body and html fill the full viewport height */
  margin: 0;
  padding: 0;
  background-color: var(--background-color); /* Uses theme background color */
  color: var(--text-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

/* Ensure main container fills remaining height */
.category-detail-container {
  min-height: calc(100vh - 40px); /* Ensures container fills remaining viewport height */
  display: flex;
  flex-direction: column;
}

/* Dropdown Menu Styles */
.dropdownMenu {
  background-color: var(--dropdown-background);
  color: var(--dropdown-text-color);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdownItem {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  cursor: pointer;
  color: var(--dropdown-text-color);
  transition: background-color 0.3s ease;
}

.dropdownItem:hover {
  background-color: var(--dropdown-hover-background);
}

/* Theme Toggle Button Specific Styling */
.theme-toggle-button {
  background-color: transparent;
  border: none;
  text-align: left;
  font-weight: 500;
  color: var(--dropdown-text-color);
}

/* Logout Button Styling */
.logout-button {
  background-color: transparent;
  border: none;
  color: #d12c28; /* Different color for logout */
  text-align: left;
}

/* General Scrollbar Styling */
::-webkit-scrollbar {
  width: 16px; /* Increased width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: var(--background-color); /* Track color */
  border-radius: 8px; /* Smoother track corners */
}

::-webkit-scrollbar-thumb {
  background-color: var(--hover-background); /* Thumb color */
  border-radius: 8px; /* Smoother thumb corners */
  border: 4px solid var(--background-color); /* Increased space between thumb and track */
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--button-background); /* Darker thumb color on hover */
}

/* Scrollbar Styling for Dark Mode */
body.dark ::-webkit-scrollbar-track {
  background: var(--section-background); /* Adjust for dark mode */
}

body.dark ::-webkit-scrollbar-thumb {
  background-color: var(--link-color); /* Thumb color for dark mode */
  border: 4px solid var(--section-background); /* Adjusted border for dark mode */
}

body.dark ::-webkit-scrollbar-thumb:hover {
  background-color: var(--hover-background); /* Darker hover color for dark mode */
}

/* Firefox Scrollbar Styling */
* {
  scrollbar-width: auto; /* Adjust scrollbar width */
  scrollbar-color: var(--hover-background) var(--background-color); /* Thumb and track colors */
}

/* Specific Container Scrollbars (Optional) */
.category-detail-container {
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

.category-detail-container::-webkit-scrollbar {
  width: 14px; /* Adjust width for specific container */
}

.category-detail-container::-webkit-scrollbar-thumb {
  background-color: var(--hover-background);
  border-radius: 6px;
  border: 3px solid var(--background-color);
}
