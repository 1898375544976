/* Public Profile Container */
.public-profile {
    padding: 20px;
    max-width: 600px;
    margin: 20px auto;
    background: var(--card-background);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-top: 3px solid var(--primary-color); /* Add a top border for separation */
}

/* Section Spacing Lines */
.profile-header {
    margin-bottom: 30px; /* Add more space below the header */
    border-bottom: 1px solid var(--border-color); /* Add a separator below the header */
    padding-bottom: 20px; /* Space above the separator */
}

.profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid var(--border-color);
}

.profile-role {
    font-size: 18px;
    color: var(--text-secondary);
    margin-bottom: 5px;
}

.profile-joined {
    font-size: 14px;
    color: var(--text-muted);
}

.profile-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    background-color: var(--button-background);
    color: var(--button-text);
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 15px;
}

.profile-actions button:hover {
    background-color: var(--hover-background);
}

/* Section Styling */
.profile-actions {
    margin-top: 20px;
    border-top: 1px solid var(--border-color); /* Separator above actions */
    padding-top: 20px; /* Space below the separator */
}

.profile-section {
    margin-top: 10px;
    text-align: left;
}

.profile-section h2 {
    font-size: 20px;
    margin-bottom: 15px;
    color: var(--text-primary);
}

/* List Styling */
.request-list,
.vote-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.request-item,
.vote-item {
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background: var(--section-background);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.request-item h3,
.vote-item h3 {
    font-size: 16px;
    margin: 0 0 5px 0;
    color: var(--text-primary);
}

.request-item p,
.vote-item p {
    font-size: 14px;
    color: var(--text-muted);
    margin: 2px 0;
}

/* Add Hover Effect for Items */
.request-item:hover,
.vote-item:hover {
    background: var(--hover-background);
    cursor: pointer;
}

/* Role Badge */
.role-badge {
    display: inline-block;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: capitalize;
    border-radius: 4px;
    padding: 4px 8px;
    color: white;
}

/* Role-Specific Colors */
.role-badge.admin {
    background-color: #d9534f;
}

.role-badge.moderator {
    background-color: green;
}

.role-badge.user {
    background-color: #5cb85c;
}

.role-badge.member {
    background-color: orange;
}


/* Notifications */
.notifications {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.notification {
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    min-width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.notification.success {
    background-color: #4caf50;
}

.notification.error {
    background-color: #f44336;
}

/* Message Box */
.message-box {
    display: block; /* Ensure block-level for proper width */
    width: calc(100% - 20px); /* Add padding space on both sides */
    margin: 10px auto; /* Center and add spacing above/below */
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    resize: none;
    font-size: 14px;
    min-height: 100px;
    background: var(--input-background);
    color: var(--text-primary);
    box-sizing: border-box; /* Include padding in width calculation */
}

.message-box:focus {
    outline: none;
    border-color: var(--primary-color);
}

textarea {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 14px;
    background: var(--input-background);
    color: var(--text-primary);
}

textarea:focus {
    outline: none;
    border-color: var(--primary-color);
}

/* Toggle Button Styling */
.toggle-button-profile {
    display: inline-block;
    padding: 10px 15px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    color: var(--button-text);
    background-color: var(--button-background);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.toggle-button-profile:hover {
    background-color: var(--hover-background);
    transform: translateY(-2px);
}

.toggle-button-profile:active {
    transform: translateY(1px);
}

/* Error Message Styling */
.error {
    color: var(--danger-text);
    font-size: 14px;
    margin-top: 5px;
}

.profile-role-and-badges {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.badge-icons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.badge-icon {
    font-size: 1.5rem;
}

.badge-icon-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.badge-icon-wrapper .btooltip {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--tooltip-background, #333);
    color: var(--tooltip-text, #fff);
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Hover for Desktop */
.badge-icon-wrapper:hover .btooltip {
    opacity: 1;
    visibility: visible;
}

/* Click-based Tooltip for Mobile */
.badge-icon-wrapper.active .btooltip {
    opacity: 1;
    visibility: visible;
}

/* About Me Section Styling */
.about-me-section-p {
    background: var(--section-background); /* Match the section background color */
    max-width: 100%; /* Adjust to container size */
    animation: fadeIn 0.4s ease-in-out; /* Smooth entry animation */
}

/* About Me Title Styling */
.about-me-title-p {
    font-size: 20px; /* Match section headers */
    font-weight: bold;
    color: var(--text-primary); /* Match primary text color */
    margin-bottom: 10px; /* Spacing below title */
    text-transform: capitalize;
    border-bottom: 2px solid var(--primary-color); /* Subtle title underline */
    padding-bottom: 5px; /* Space for underline */
}

/* About Me Content Styling */
.about-me-content-p {
    font-size: 16px; /* Readable font size */
    color: var(--text-secondary); /* Match secondary text color */
    line-height: 1.6; /* Improve readability */
    margin: 10px 0 0; /* Spacing above and below */
    padding: 10px; /* Internal padding */
    background: var(--card-background); /* Subtle card background for content */
    border-radius: 6px; /* Rounded corners */

}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Dark background with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
}

/* Modal Container */
.modal {
    background: var(--card-background); /* Use existing card background color */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    width: 400px;
    max-width: 90%;
    animation: fadeIn 0.3s ease-in-out;
    text-align: center;
}

/* Modal Title */
.modal h2 {
    font-size: 22px;
    margin-bottom: 15px;
    color: var(--text-primary);
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 10px;
}

/* Dropdown and Textarea */
.modal select,
.modal textarea {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 14px;
    background: var(--input-background);
    color: var(--text-primary);
    box-sizing: border-box;
}

/* Modal Buttons */
.modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.modal-actions button {
    padding: 10px 15px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-actions button:first-child {
    background-color: var(--danger-background, #f44336); /* Use existing danger color */
    color: #fff;
}

.modal-actions button:last-child {
    background-color: var(--button-background);
    color: var(--button-text);
}

.modal-actions button:hover {
    background-color: var(--hover-background);
    transform: translateY(-2px);
}

/* Fade In Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Media Query for Small Screens */
@media (max-width: 480px) {
    .public-profile {
        padding: 15px;
    }

    .profile-avatar {
        width: 80px;
        height: 80px;
    }

    .profile-role {
        font-size: 16px;
    }

    .profile-joined {
        font-size: 12px;
    }

    .profile-section h2 {
        font-size: 18px;
    }

    .request-item h3,
    .vote-item h3 {
        font-size: 14px;
    }

    .request-item p,
    .vote-item p {
        font-size: 12px;
    }

    textarea {
        height: 80px;
    }
    .btooltip {
        font-size: 10px;
    }
}
