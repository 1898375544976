/* ContactUs.css */
.contact-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 40px;
    background-color: var(--card-background);
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .contact-section {
    padding: 30px;
    background-color: var(--section-background);
    border: 1px solid var(--border-color);
    border-radius: 12px;
  }
  
  .contact-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: var(--text-color);
    text-align: center;
  }
  
  .contact-section p {
    font-size: 1.125rem;
    margin-bottom: 25px;
    color: var(--text-color-secondary);
    text-align: center;
  }
  
  .contact-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .contact-section li {
    margin-bottom: 20px;
    font-size: 1.125rem;
    color: var(--text-color);
    text-align: center;
  }
  
  .contact-section a {
    color: var(--link-color);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease, text-decoration 0.3s ease;
  }
  
  .contact-section a:hover {
    color: var(--hover-background);
    text-decoration: underline;
  }
  