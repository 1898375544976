.adminpanel-container {
    display: flex;
    flex-direction: column;
    flex: 1; /* Fills available space */
    width: 100%;
    padding: 0 20px; /* Adds spacing on the left and right */
    box-sizing: border-box; /* Ensures padding doesn't affect width */
}

/* Admin Panel Layout */
.adminpanel-layout {
    display: flex;
    min-height: 100vh; /* Ensure it stretches to fill the viewport height */
    flex-direction: row; /* Sidebar on the left, content on the right */
    background-color: var(--main-background); /* Optional for overall layout */
}

/* Section Styling */
.adminpanel-section {
    background-color: var(--section-background);
    padding: 25px;
    margin-bottom: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    border-left: 5px solid var(--link-color);
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Form Container Styling */
.adminpanel-container form {
    max-width: 600px; /* Control form width */
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center; /* Center-aligns contents inside the form */
}

/* Center input fields within the form */
.adminpanel-section form .upload-input, 
.adminpanel-section form .category-select, 
.adminpanel-section form textarea {
    width: 80%; /* Adjust the width of input fields */
    max-width: 500px; /* Optional max-width to prevent extra-large inputs */
    margin: 0 auto; /* Centers each input individually */
    text-align: center; /* Centers text inside input fields */
}

.adminpanel-container h3 {
    font-size: 1.6rem;
    margin-bottom: 15px;
    font-weight: bold;
    color: var(--text-color);
    text-align: center; 
}


/* Container for the user cards */
.user-list-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
    text-align: center; 
    gap: 16px; 
    padding: 20px;
    background-color: var(--card-background); /* Light background for contrast */
}

/* Ensures consistent sizing for user avatar */
.user-avatar-container {
    text-align: center;
    margin-bottom: 12px;
}

.user-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ccc;
}

/* General styles for user info text */
.adminpanel-card p {
    margin: 8px 0;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
}

/* Status styles */
.user-status {
    font-weight: bold;
}

.user-status.banned {
    color: red;
}

.user-status.active {
    color: green;
}


/* IP history section */
.ip-history {
    margin-top: 12px;
    background-color: var(--card-background);
    padding: 12px;
}

.ip-history h5 {
    margin-bottom: 8px;
    font-size: 14px;
    color: var(--text-color);
}

.ip-history ul {
    margin: 0;
    padding-left: 20px;
    list-style: disc;
}

.ip-history li {
    margin-bottom: 4px;
    font-size: 13px;
    color: var(--text-color);
}

.user-card {
    position: relative;
    border: 1px solid var(--border-color);
    padding: 20px;
    margin-bottom: 5px;
    border-radius: 12px;
    background-color: var(--card-background);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
}

/* Section Headers */
.section-header {
    background-color: var(--section-header-bg);
    padding: 12px 20px;
    border-bottom: 1px solid var(--border-color);
    border-radius: 5px 5px 0 0;
    font-weight: bold;
    font-size: 1.4rem;
    color: var(--text-color);
}

.adminpanel-title {
    color: var(--link-color);
    text-align: center;
    animation: fadeIn 1.2s ease-in-out;
    margin-bottom: 16px; /* Add some spacing below the title */
    padding-bottom: 8px; /* Optional padding for better spacing */
    border-bottom: 2px solid var(--border-color); /* Add the line */
}

.adminpanel-success {
    color: #28a745; /* Green */
    text-align: center;
    background-color: #d4edda;
    padding: 10px;
    border-radius: 4px;
    margin: 10px 0;
    animation: fadeOut 0.5s ease-in-out 4.5s forwards; /* Starts fading out after 4.5s */
}

.adminpanel-error {
    color: #dc3545; /* Red */
    text-align: center;
    background-color: #f8d7da;
    padding: 10px;
    border-radius: 4px;
    margin: 10px 0;
    animation: fadeOut 0.5s ease-in-out 4.5s forwards; /* Starts fading out after 4.5s */
}

@keyframes fadeOut {
    to {
        opacity: 0;
        transform: translateY(-10px);
    }
}

.adminpanel-card {
    position: relative;
    border: 1px solid var(--border-color);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 12px;
    background-color: var(--card-background);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
}

.adminpanel-card:hover {
    transform: translateY(-5px);
}

/* Form input and button styles */
.upload-input, .category-select {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 1px solid var(--border-color);
    font-size: 1rem;
    background-color: var(--input-background);
    color: var(--input-text);
    box-sizing: border-box;
}

.upload-button, .cancel-button {
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    padding: 14px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    width: 100%;
}

.upload-button:hover, .cancel-button:hover {
    background-color: var(--hover-background);
    transform: scale(1.03);
}

.cancel-button {
    background-color: #e74c3c;
}

.cancel-button:hover {
    background-color: #c0392b;
}

/* Table Cards */
.table-card {
    padding: 18px;
    border: 1px solid var(--border-color);
    background-color: var(--section-background);
    margin-bottom: 15px;
    border-radius: 8px;
    border-left: 4px solid var(--link-color);
}

/* Category Buttons */
.category-button {
    padding: 12px 18px;
    border: 1px solid var(--link-color);
    border-radius: 8px;
    cursor: pointer;
    background-color: var(--link-color);
    color: var(--button-text);
    font-weight: bold;
    margin-right: 8px;
    margin-bottom: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.category-button:hover {
    background-color: var(--hover-background);
    transform: translateY(-2px);
}

/* Sidebar: Position it outside the main container */
.adminpanel-sidebar {
    top: 0;
    left: 0;
    width: 220px; /* Adjust width as needed */
    background: linear-gradient(135deg, #2f1235, #313c52); /* Keep original background */
    padding: 20px 10px;
    box-shadow: 2px 0 12px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Add scroll for longer content */
}

/* Sidebar Tabs */
.sidebar-tabs {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* Sidebar tab items */
.sidebar-tab {
    display: flex; /* Ensure icon and text are aligned in a row */
    align-items: center; /* Vertically align the icon and text */
    padding: 12px 20px;
    margin-bottom: 8px;
    border-radius: 6px;
    cursor: pointer;
    background-color: transparent;
    color: white;
    transition: background-color 0.3s ease;
    text-align: left;
    user-select: none; /* Prevent text selection */
}

/* Active Tab */
.sidebar-tab.active {
    background: linear-gradient(90deg, #0056e0, #007bff, #ffffff, #007bff, #0056e0);
    background-size: 300% 100%; /* Larger size for a smooth effect */
    color: var(--button-text);
}

/* Hover State */
.sidebar-tab:hover {
    background: linear-gradient(90deg, #0056e0, #007bff, #ffffff, #007bff, #0056e0);
    background-size: 300% 100%; /* Larger size for a smooth effect */
    color: white;
}

/* Icons in sidebar */
.sidebar-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: 18px; 
    margin-right: 12px; 
    color: white; 
    text-align: center; 
    line-height: 20px; 
}

.sidebar-tab.active .sidebar-icon {
    color: white; /* Change color for active tab */
}


/* Button Styles */
.adminpanel-button {
    padding: 12px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin: 5px;
}

.adminpanel-button.modify {
    background-color: #f39c12;
    color: var(--button-text);
}

.adminpanel-button.modify:hover {
    background-color: #e67e22;
}

.adminpanel-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}

.adminpanel-button.complete {
    background-color: var(--success-color);
    color: var(--button-text);
}

.adminpanel-button.complete:hover {
    background-color: #218838;
}

.adminpanel-button.approve {
    background-color: var(--success-color);
    color: var(--button-text);
}

.adminpanel-button.approve:hover {
    background-color: #218838;
}

.adminpanel-button.reject {
    background-color: var(--error-color);
    color: var(--button-text);
}

.adminpanel-button.reject:hover {
    background-color: #e0a800;
}

.adminpanel-button.delete {
    background-color: #dc3545;
    color: var(--button-text);
}

.adminpanel-button.delete:hover {
    background-color: #c82333;
}

/* Additional Button Styles for Ban/Unban */
.adminpanel-button.ban {
    background-color: #e74c3c;
    color: var(--button-text);
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.adminpanel-button.ban:hover {
    background-color: #c0392b;
    transform: scale(1.05);
}

.adminpanel-button.unban {
    background-color: #2ecc71;
    color: var(--button-text);
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.adminpanel-button.unban:hover {
    background-color: #27ae60;
    transform: scale(1.05);
}

/* Fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


.adminpanel-card h4 {
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--text-color);
    margin: 0; /* Remove margin to avoid extra space */
}

.adminpanel-card p {
    margin: 5px 0;
    font-size: 1rem;
    color: var(--text-color);
}

/* Adjust the styling for the key text */
.adminpanel-card p strong {
    flex-shrink: 0; /* Prevents key from shrinking */
    font-weight: bold; /* Ensures the key stands out */
}

/* Styling for the value */
.adminpanel-card p span {
    flex-grow: 1; /* Ensures value takes up remaining space */
    text-align: right; /* Aligns value to the right */
}

.adminpanel-card a {
    color: var(--link-color);
    text-decoration: none;
}

.adminpanel-card a:hover {
    text-decoration: underline;
}

.steam-search{
    align-items: center;
    text-align: center;
}

/* Search Button Styling */
.search-button {
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: var(--button-text);
    background-color: var(--link-color);
    border: none;
    border-radius: 8px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.search-button:hover {
    background-color: var(--hover-background);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
}

.search-button:disabled {
    background-color: var(--disabled-background);
    color: var(--disabled-text-color);
    cursor: not-allowed;
    opacity: 0.6;
    box-shadow: none;
}

/* Steam Search Results Styling */
.steam-results {
    margin-top: 20px;
    padding: 20px;

}

.steam-results h4 {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: var(--text-color);
}

.user-count {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 10px;
}

.user-search-input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 1rem;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.steam-result-item {
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 10px;
    background-color: var(--card-background);
    color: var(--link-color);
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
}

.steam-result-item:hover {
    background-color: var(--hover-background);
    color: var(--link-color);
}

.steam-result-icon {
    font-size: 1.2rem;
    color: var(--link-color);
}

/* Themed Checkbox Wrapper */
.checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 15px;
}

/* Hide the default checkbox */
input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    width: 0;
    height: 0;
}

/* Custom Checkbox Style */
.custom-checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid var(--link-color);
    border-radius: 4px;
    background-color: var(--input-background);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

/* Checkbox Checkmark */
.custom-checkbox::after {
    content: '✔';
    font-size: 14px;
    color: var(--button-text);
    display: none;
}

/* Checkbox Checked State */
input[type="checkbox"]:checked + .custom-checkbox {
    background-color: var(--link-color);
    border-color: var(--link-color);
}

input[type="checkbox"]:checked + .custom-checkbox::after {
    display: block;
}

/* Pagination Container */
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px; /* Add spacing between buttons */
    margin: 20px 0;
    flex-wrap: wrap; /* Ensures it wraps on small screens */
}

/* Pagination Text Styling */
.pagination-text {
    margin: 0 15px; /* Adds space on both sides of the text */
    font-weight: bold;
    color: var(--text-color);
}

/* Pagination Button Styling */
.pagination-button {
    padding: 8px 12px;
    margin: 0 5px; /* Horizontal spacing between buttons */
    border: 1px solid var(--link-color);
    background-color: var(--button-background);
    color: white;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.pagination-button:hover {
    background-color: var(--hover-background);
    transform: scale(1.05);
}

.pagination-button.active {
    background-color: var(--link-color);
    color: var(--text-color);
    border-color: var(--link-color);
}

.pagination-button:disabled {
    background-color: #e0e0e0;
    color: #999;
    border-color: #ccc;
    cursor: not-allowed;
    transform: none;
}

.pagination-controls {
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* Ellipsis Styling */
.pagination-ellipsis {
    display: inline-block;
    padding: 8px 12px;
    margin: 0 5px; /* Space around ellipsis */
    font-size: 1rem;
    color: var(--text-color-secondary, #888);
    user-select: none;
}

/* Search Input Styling */
.user-search-input {
    width: 80%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    font-size: 1rem;
    background-color: var(--input-background);
    color: var(--input-text);
    transition: background-color 0.3s ease, border-color 0.3s ease;
}
.search-section {
    text-align: center;
}

.user-search-input:focus {
    border-color: var(--link-color);
    outline: none;
}

.role-button-section {
    text-align: center;
    justify-items: center;
}

/* Request Filter Tabs Styling */
.request-filter-tabs {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    gap: 10px;
}

.request-filter-tab {
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    background-color: var(--input-background);
    color: var(--text-color);
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    border: 1px solid var(--border-color);
}

.request-filter-tab.active {
    background-color: var(--link-color);
    color: var(--button-text);
}

.request-filter-tab:hover {
    background-color: var(--hover-background);
    transform: scale(1.03);
}

.context-menu-container {
    position: absolute;
    top: 10px; /* Adjust vertical positioning */
    right: 10px; /* Adjust horizontal positioning */
}

.menu-button {
    background: none;
    border: none;
    color: var(--text-color);
    cursor: pointer;
    font-size: 25px;
    padding: 5px;
}

.dropdown-menu {
    position: absolute;
    top: 100%; /* Position the dropdown below the button */
    right: 0; /* Align the dropdown to the right of the button */
    background: white;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.dropdown-menu button {
    display: block;
    font-size: 20px;
    width: 100%;
    background: none;
    border: none;
    color: var(--text-color);
    text-align: left;
    padding: 5px 10px;
    cursor: pointer;
}

.dropdown-menu button:hover {
    background-color: var(--hover-background);
}

/* Ban Details Button */
.adminpanel-button.ban-details {
    background-color: #ff9800; /* Orange color to distinguish from other buttons */
    color: var(--button-text);
    border: none;
    padding: 6px 12px; /* Smaller padding for a compact button */
    border-radius: 6px;
    font-size: 0.9rem; /* Slightly smaller font size */
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-left: 10px; /* Space from the status text */
}

.adminpanel-button.ban-details:hover {
    background-color: #fb8c00; /* Darker orange on hover */
    transform: scale(1.05);
}

/* Confirmation Modal */
.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
    animation: fadeIn 0.3s ease; /* Apply fade-in animation */
}

/* Adjust Modal Content for Ban Details */
.modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align text to the left for readability */
    gap: 15px; /* Consistent spacing between elements */
    background-color: var(--card-background);
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 90%; /* Responsive width */
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    color: var(--text-color);
}

/* Modal Header */
.modal-content h3 {
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--link-color);
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
}

/* Modal Text */
.modal-content p {
    font-size: 1rem;
    margin: 5px 0;
    color: var(--text-color);
    line-height: 1.5;
}

/* Confirmation and Cancel Buttons */
.confirm-button, .cancel-button {
    width: 45%; /* Ensure both buttons take up equal space */
    padding: 12px;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-size: 1rem;
    margin: 10px 5px; /* Add consistent margin around each button */
    box-sizing: border-box;
}

/* Modal Button Container */
.modal-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 15px;
}

.confirm-button {
    background-color: var(--success-color);
    color: var(--button-text);
    border: none;
}

.confirm-button:hover {
    background-color: #218838;
}

/* Close Button */
.cancel-button {
    background-color: #e74c3c; /* Red for close button */
    color: var(--button-text);
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.cancel-button:hover {
    background-color: #c0392b; /* Darker red on hover */
    transform: scale(1.05);
}

/* Centering container for the charts */
.analytics-chart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px; /* Optional: Add some top margin if needed */
}

/* Chart container styling */
.analytics-chart-container {
    width: 500px; /* Adjust width as needed */
    height: 200px; /* Adjust height as needed */
    margin-bottom: 20px;
}

/* Styling for summary numbers */
.analytics-summary-number {
    font-size: 1.8rem; /* Adjust font size as desired */
    font-weight: bold;
    margin-bottom: 5px;
}

.show-voters {
    background: none;
    border: none;
    color: var(--link-color);
    font-size: 0.9rem;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
    margin-top: 10px;
    display: inline;
}

/* Voter List Styling */
.voter-list {
    margin-top: 15px;
    padding: 15px;
    background-color: var(--section-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.voter-list h5 {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--link-color);
    margin-bottom: 10px;
    text-align: center;
}

.voter-list ul {
    padding-left: 0;
    list-style: none;
}

.voter-list li {
    display: flex;
    align-items: center;
    padding: 8px;
    margin-bottom: 8px;
    background-color: var(--input-background);
    border-radius: 6px;
    color: var(--text-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.voter-list li:hover {
    transform: translateX(5px);
}

.voter-list li::before {
    content: "👤"; /* Add a user icon before each voter name */
    font-size: 1.2rem;
    margin-right: 10px;
    color: var(--link-color);
}

.form-switch-buttons {
    display: flex;
    gap: 10px; /* Space between buttons */
    margin-top: 20px; /* Space between the dropdown and buttons */
}


/* Selected Category Info */
.selected-category-info {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 20px;
}

/* Delete Button for Category */
.delete-button {
    background-color: var(--error-color);
    color: var(--button-text);
    border: none;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 15px;
    width: 100%; /* Full width for consistent alignment */
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.delete-button:hover {
    background-color: #c0392b; /* Darker red for hover */
    transform: scale(1.05);
}

/* Form Transition Effects */
.form-container {
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 1;
    transform: translateY(0);
}

.form-container.transitioning {
    opacity: 0;
    transform: translateY(-10px);
}

/* Form Switching Buttons Container */
.form-switch-buttons {
    display: flex;
    justify-content: center;
    gap: 15px; /* Space between buttons */
    margin-bottom: 20px; /* Add space below the buttons */
}

/* Manage Games and Manage Tables Buttons */
.switch-button {
    background-color: var(--button-background);
    color: var(--button-text);
    border: 1px solid var(--border-color);
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    min-width: 150px; /* Ensure consistent button width */
    text-align: center;
}

/* Hover and Focus States */
.switch-button:hover {
    background-color: var(--hover-background);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
}

.switch-button:focus {
    outline: none;
    border-color: var(--link-color);
    box-shadow: 0 0 6px rgba(0, 123, 255, 0.5);
}

/* Active State for Buttons */
.switch-button.active {
    background-color: var(--link-color);
    color: var(--button-text);
    border-color: var(--link-color);
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
    transform: scale(1.05);
}

/* Disabled State */
.switch-button:disabled {
    background-color: var(--disabled-background);
    color: var(--disabled-text-color);
    cursor: not-allowed;
    border: 1px solid var(--disabled-border-color);
    opacity: 0.6;
}

/* Dropdown Menu Styling */
.dropdown-menu {
    position: absolute;
    top: 30px; /* Adjust positioning as needed */
    right: 0;
    min-width: 150px;
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* Dropdown Item Styling */
.dropdown-item {
    display: block;
    width: 100%;
    padding: 10px 15px;
    text-align: left;
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--text-color); /* Ensures consistent text color */
    font-size: 1rem;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 4px;
}

/* Hover and Focus States */
.dropdown-item:hover {
    background-color: var(--hover-background); /* Matches hover styling */
    color: var(--button-text); /* Contrasting text color for readability */
}

/* Disabled Item Styling */
.dropdown-item:disabled {
    background-color: var(--disabled-background);
    color: var(--disabled-text-color);
    cursor: not-allowed;
}

/* Add specific dropdown-item states for customization */
.dropdown-item.approve {
    color: var(--success-color);
}

.dropdown-item.reject {
    color: var(--error-color);
}

.dropdown-item.complete {
    color: var(--link-color);
}

.dropdown-item.delete {
    color: var(--error-color);
}

/* Game Section Container */
.game-section {
    background-color: var(--section-background);
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 8px;
    border-left: 6px solid var(--link-color);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.game-link {
    text-decoration: none;
    color: var(--link-color); /* Blue link color */
    font-weight: bold;
    transition: color 0.3s ease;
}

.game-link:hover {
    color: var(--hover-background); /* Darker blue on hover */
    text-decoration: underline;
}

/* User Counts */
.user-counts-admin {
    display: flex;
    gap: 15px;
    font-size: 15px;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
}

.user-counts-admin span {
    background: var(--section-header-bg);
    padding: 8px 14px;
    border-radius: 20px;
    color: var(--text-color);
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    white-space: nowrap;
}

.user-counts-admin span:hover {
    transform: scale(1.05);
}

/* Role Filter */
.role-filter-admin {
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    background: var(--section-background);
    color: var(--text-color);
    font-size: 14px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    min-width: 150px;
    box-sizing: border-box;
}

.role-filter-admin:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.profile-link {
    color: var(--link-color); /* Customize your link color */
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.profile-link:hover {
    color: var(--link-hover-color); /* Customize hover color */
    text-decoration: underline;
}

.icon-verified {
    color: green;
    font-weight: bold;
}

.icon-not-verified {
    color: red;
    font-weight: bold;
}



/* Game Section Header */
.game-section h4 {
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--link-color);
    margin-bottom: 15px;
}

/* Total Reports */
.game-section p {
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 20px;
}

/* Individual Report Container */
.report-item {
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

/* Hover Effect for Report Items */
.report-item:hover {
    transform: translateY(-3px);
}

/* Report Details */
.report-item p {
    font-size: 1rem;
    margin: 5px 0;
    color: var(--text-color);
}

/* Resolve Button Styling */
.resolve-reports-button {
    background-color: var(--success-color);
    color: var(--button-text);
    padding: 10px 15px;
    font-size: 1rem;
    border: none;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Resolve Button Hover */
.resolve-reports-button:hover {
    background-color: #218838;
    transform: scale(1.05);
}

/* Empty State Styling */
.no-reports {
    text-align: center;
    font-size: 1.2rem;
    color: var(--text-color);
    margin-top: 20px;
}

/* Section Headers */
.section-header {
    background-color: var(--section-header-bg);
    padding: 12px 20px;
    border-bottom: 1px solid var(--border-color);
    border-radius: 8px 8px 0 0;
    font-weight: bold;
    font-size: 1.4rem;
    color: var(--text-color);
    margin-bottom: 15px;
    text-align: center;
}

.clear-status-button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #f44336; /* Red */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.clear-status-button:hover {
    background-color: #d32f2f; /* Darker red */
}

.status-dropdown {
    margin-left: 15px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    font-size: 14px;
    color: #333;
    cursor: pointer;
}

.status-dropdown:focus {
    border-color: #007bff;
    outline: none;
}

.toggle-ip-history-button {
    background-color: var(--button-background); /* Primary button color */
    color: #fff; /* White text */
    border: none; /* Remove border */
    border-radius: 6px; /* Rounded corners */
    padding: 10px 15px; /* Button padding */
    font-size: 14px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effect */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.toggle-ip-history-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-2px); /* Slight lift on hover */
}

.toggle-ip-history-button:active {
    background-color: #004080; /* Even darker blue on click */
    transform: translateY(0); /* Reset lift on click */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Deeper shadow on click */
}

.toggle-ip-history-button:disabled {
    background-color: #ccc; /* Gray for disabled state */
    color: #666; /* Dim text for disabled state */
    cursor: not-allowed; /* Disable pointer */
    box-shadow: none; /* Remove shadow */
}

.text-success {
    color: var(--success-color); /* Green for success */
    font-weight: bold;
}

.text-error {
    color: var(--error-color); /* Red for errors */
    font-weight: bold;
}

.text-info {
    color: var(--link-color); /* Blue for informational text */
    font-weight: bold;
}

.user-status.active {
    color: var(--success-color); /* Green for active users */
}

.user-status.banned {
    color: var(--error-color); /* Red for banned users */
}

.send-website-message-section {
    margin-top: 20px;
    padding: 20px;
    background: var(--secondary-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    text-align: center;
}

.website-message-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    font-size: 14px;
}

.send-website-message-button {
    background-color: var(--link-color);
    color: var(--button-text);
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.send-website-message-button:hover {
    background-color: var(--link-hover-color);
}

.send-website-message-button:disabled {
    background-color: var(--disabled-background);
    cursor: not-allowed;
}

/* Container for the toggle */
.maintenance-mode-toggle {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 1.2rem;
    color: var(--text-color);
    margin-bottom: 20px;
}

/* Custom checkbox wrapper */
.custom-checkbox-label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
}

/* Custom checkbox styling */
.custom-checkbox-label::before {
    content: "";
    width: 20px;
    height: 20px;
    border: 2px solid var(--link-color);
    border-radius: 4px;
    background-color: var(--input-background);
    display: inline-block;
    transition: background-color 0.3s, border-color 0.3s;
}

/* Checkmark styling */
.custom-checkbox-label::after {
    content: "✔";
    color: var(--button-text);
    font-size: 14px;
    position: absolute;
    top: 2px;
    left: 5px;
    display: none;
}

/* Show the checkmark when checked */
input[type="checkbox"]:checked + .custom-checkbox-label::after {
    display: block;
}

/* Change the checkbox background when checked */
input[type="checkbox"]:checked + .custom-checkbox-label::before {
    background-color: var(--link-color);
    border-color: var(--link-color);
}

/* Allowed IPs List */
.allowed-ips-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    font-size: 1rem;
    color: var(--text-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--card-background);
    padding: 15px;
}

.allowed-ips-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--input-background);
    transition: background-color 0.2s ease, transform 0.2s ease;
}

.allowed-ips-list li:hover {
    background-color: var(--hover-background);
    transform: translateX(5px);
}

.remove-ip-button {
    background-color: var(--error-color);
    border: none;
    color: var(--button-text);
    padding: 6px 12px;
    font-size: 0.9rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.remove-ip-button:hover {
    background-color: #c0392b; /* Darker red for hover */
    transform: scale(1.05);
}

/* Add IP Section */
.add-ip-section {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
}

.add-ip-section .upload-input {
    padding: 12px;
    font-size: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    width: 70%;
    box-sizing: border-box;
    background-color: var(--input-background);
    color: var(--input-text);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.add-ip-section .upload-input:focus {
    border-color: var(--link-color);
    box-shadow: 0 0 6px rgba(0, 123, 255, 0.5);
}

.add-ip-button {
    background-color: var(--link-color);
    color: var(--button-text);
    border: none;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-bottom: 15px; /* Added bottom margin to align with input */
}

.add-ip-button:hover {
    background-color: var(--hover-background);
    transform: scale(1.05);
}

.maintenance-message {
    background-color: var(--error-color);
    color: var(--button-text);
    padding: 15px 20px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logs-grid-container {
    margin-top: 20px;
}

.logs-grid {
    display: flex;
    flex-direction: column; /* Stack pairs vertically */
    gap: 25px; /* Space between pairs */
}

.log-pair {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two cards side by side */
    gap: 25px; /* Space between cards in a pair */
}

.log-card {
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 25px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    font-size: 18px;
}

.log-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.log-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.log-method,
.log-status {
    padding: 8px 12px;
    border-radius: 6px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

/* Tooltip for Status Codes */
.log-status {
    position: relative; /* Required for positioning the tooltip */
    cursor: help; /* Show a help cursor on hover */
}

/* Tooltip Content */
.log-status[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: calc(100% + 8px); /* Position below the status code with a small gap */
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--dropdown-background);
    color: var(--dropdown-text-color);
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 0.9rem;
    max-width: 200px; /* Prevent overly wide tooltips */
    white-space: normal; /* Allow text wrapping for long messages */
    text-align: center; /* Center-align text */
    z-index: 1000; /* Ensure it’s above other elements */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 1;
    transition: opacity 0.2s ease;
}

/* Tooltip Arrow */
.log-status[data-tooltip]:hover::before {
    content: '';
    position: absolute;
    top: 100%; /* Position above the tooltip content */
    left: 50%;
    transform: translateX(-50%);
    border: 6px solid transparent;
    border-bottom-color: var(--dropdown-background);
    z-index: 1000; /* Ensure it’s above other elements */
    transition: opacity 0.2s ease;
}

/* Ensure tooltip is hidden by default */
.log-status[data-tooltip]::after,
.log-status[data-tooltip]::before {
    opacity: 0;
    pointer-events: none;
}

/* Show tooltip on hover */
.log-status[data-tooltip]:hover::after,
.log-status[data-tooltip]:hover::before {
    opacity: 1;
}

/* Adjust tooltip for dark mode */
body.dark .log-status[data-tooltip]:hover::after {
    background-color: var(--dropdown-background); /* Matches dark mode dropdown background */
    color: var(--dropdown-text-color); /* Matches dark mode text color */
}

body.dark .log-status[data-tooltip]:hover::before {
    border-bottom-color: var(--dropdown-background); /* Matches dark mode dropdown background */
}

/* Prevent tooltips from going offscreen */
.log-status[data-tooltip] {
    position: relative;
}

/* Adjust tooltip position when near the top of the screen */
.log-status[data-tooltip].tooltip-top::after {
    top: auto;
    bottom: calc(100% + 8px); /* Position above the status code */
    transform: translateX(-50%);
}

/* Adjust arrow for top-positioned tooltip */
.log-status[data-tooltip].tooltip-top::before {
    top: auto;
    bottom: 100%;
    border-bottom-color: transparent;
    border-top-color: var(--dropdown-background);
}

/* Adjust tooltip position when near the left edge */
.log-status[data-tooltip].tooltip-left::after {
    left: 0;
    transform: none;
}

/* Adjust arrow for left-positioned tooltip */
.log-status[data-tooltip].tooltip-left::before {
    left: 10px;
    transform: none;
}

/* Adjust tooltip position when near the right edge */
.log-status[data-tooltip].tooltip-right::after {
    left: auto;
    right: 0;
    transform: none;
}

/* Adjust arrow for right-positioned tooltip */
.log-status[data-tooltip].tooltip-right::before {
    left: auto;
    right: 10px;
    transform: none;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 800px) {
    .log-pair {
        grid-template-columns: 1fr; /* Stack cards vertically on small screens */
    }

    .log-status[data-tooltip]:hover::after {
        max-width: 150px; /* Smaller max-width on mobile */
        font-size: 0.8rem; /* Smaller font size */
        padding: 6px 10px; /* Adjust padding */
    }

    /* Ensure tooltips don’t overflow on small screens */
    .log-status[data-tooltip].tooltip-left::after,
    .log-status[data-tooltip].tooltip-right::after {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }

    .log-status[data-tooltip].tooltip-left::before,
    .log-status[data-tooltip].tooltip-right::before {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }
}

.log-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.log-field {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-color);
}

.log-field:last-child {
    border-bottom: none;
}

.log-label {
    font-weight: 700;
    color: var(--dropdown-text-color);
    min-width: 100px;
}

.log-value {
    color: var(--text-color);
    word-break: break-word;
    font-weight: 400;
}

/* Highlight Pattern */
.highlight-pattern {
    background-color: rgba(255, 165, 0, 0.15); /* Subtle orange for light mode */
    border-left: 5px solid #FF9800; /* Orange border */
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* Dark Mode Adjustment for Highlight Pattern */
body.dark .highlight-pattern {
    background-color: rgba(255, 165, 0, 0.3); /* Brighter orange for dark mode */
    border-left: 5px solid #FFB300; /* Lighter orange border for better contrast */
}

/* Filter styles */
.logs-filter-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.logs-search-input {
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--card-background);
    color: var(--text-color);
    flex-grow: 1;
    min-width: 200px;
}

.logs-filter-select {
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--dropdown-background);
    color: var(--dropdown-text-color);
    cursor: pointer;
}

/* Container for the users-per-page selector */
.users-per-page-selector {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
    gap: 10px;
    margin: 15px 0; /* Add vertical spacing above and below */
}

.users-per-page-selector label {
    font-size: 14px;
    color: var(--text-color);
}

.users-per-page-select {
    padding: 8px 12px;
    border: none;
    border-radius: 8px;
    background: var(--section-background);
    color: var(--text-color);
    font-size: 14px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.users-per-page-select:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

/* Input field for custom users per page */
.users-per-page-selector input[type="number"] {
    width: 100px;
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    background-color: var(--input-background);
    color: var(--input-text);
    font-size: 1rem;
    text-align: center;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.users-per-page-selector input[type="number"]:focus {
    outline: none;
    border-color: var(--link-color);
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.adminpanel-button.show-reports {
    background-color: var(--button-background); /* Bootstrap info blue */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 6px;
    font-size: 14px; /* Font size */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.adminpanel-button.show-reports:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: scale(1.05);
}

.reports-list {
    margin-top: 15px;
    background-color: var(--card-background);
    padding: 15px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.reports-list h5 {
    font-size: 1.1rem;
    color: var(--link-color);
    margin-bottom: 10px;
    text-align: center;
}

.reports-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.reports-list li {
    background-color: var(--input-background);
    padding: 10px;
    margin-bottom: 8px;
    border-radius: 6px;
    border: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.2s ease;
}

.reports-list li:hover {
    transform: translateX(5px); /* Subtle slide effect */
}

.reports-list li p {
    margin: 0;
    font-size: 0.9rem;
    color: var(--text-color);
}

.reports-list li strong {
    font-weight: bold;
    margin-right: 10px;
}

.no-reports {
    text-align: center;
    font-size: 1rem;
    color: #777;
    font-style: italic;
    margin-top: 10px;
}

/* Manage Tools Section */
.manage-tools-section {
    background-color: var(--section-background);
    padding: 25px;
    margin-bottom: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    border-left: 5px solid var(--link-color);
}

.manage-tools-section h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--text-color);
    text-align: center;
    margin-bottom: 20px;
}

/* Tool List Group */
.tool-category-group {
    margin-bottom: 30px;
}

.tool-category-group h4 {
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 15px;
}

.tool-item {
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.tool-item:hover {
    transform: translateY(-3px);
}

.tool-item h4 {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 10px;
}

.tool-item p {
    margin: 5px 0;
    font-size: 1rem;
    color: var(--text-color-secondary);
}

.tool-item a {
    color: var(--link-color);
    text-decoration: none;
    font-weight: bold;
}

.tool-item a:hover {
    text-decoration: underline;
}

/* Buttons for each tool */
.tool-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.tool-actions button {
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    padding: 10px 15px;
    font-size: 0.9rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.tool-actions button:hover {
    transform: scale(1.05);
}

.tool-actions .edit-button {
    background-color: var(--success-color);
}

/* Save Edit Button */
.save-edit-button {
    background-color: #28a745; /* Green for save */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-right: 10px; /* Space between buttons */
}

.save-edit-button:hover {
    background-color: #218838;
    transform: scale(1.05);
}

/* Cancel Edit Button */
.cancel-edit-button {
    background-color: #dc3545; /* Red for cancel */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.cancel-edit-button:hover {
    background-color: #c82333;
    transform: scale(1.05);
}


/* Updated class name: remove-tool-button */
.remove-tool-button {
    background-color: #e74c3c; /* Red background for delete */
    color: white; /* White text */
    border: none;
    padding: 10px 15px; /* Adjust padding */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    font-size: 1rem; /* Adjust font size */
    font-weight: bold; /* Bold text */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

.remove-tool-button:hover {
    background-color: #c0392b; /* Darker red on hover */
    transform: scale(1.05); /* Slight scaling effect */
}

.remove-tool-button:disabled {
    background-color: #e0e0e0; /* Light gray background */
    color: #999; /* Dimmed text color */
    cursor: not-allowed; /* Show not-allowed cursor */
    transform: none; /* No scaling when disabled */
    opacity: 0.6; /* Reduced opacity */
}

/* Add Tool Form */
.add-tool-form {
    background-color: var(--card-background);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
}

.add-tool-form h4 {
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--text-color);
    text-align: center;
    margin-bottom: 15px;
}

.add-tool-form form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.add-tool-form input,
.add-tool-form textarea {
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid var(--border-color);
    background-color: var(--input-background);
    color: var(--input-text);
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.add-tool-form input:focus,
.add-tool-form textarea:focus {
    border-color: var(--link-color);
    outline: none;
    box-shadow: 0 0 6px rgba(0, 123, 255, 0.5);
}

.add-tool-button {
    padding: 10px 15px;
    background-color: var(--success-color);
    color: var(--button-text);
    border: none;
    border-radius: 6px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-tool-button:hover {
    background-color: var(--hover-background);
    transform: scale(1.05);
}

/* Category Select Dropdown */
.add-tool-form select {
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid var(--border-color);
    background-color: var(--input-background);
    color: var(--input-text);
    font-size: 1rem;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.add-tool-form select:focus {
    border-color: var(--link-color);
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

/* No Tools Available */
.no-tools {
    text-align: center;
    font-size: 1.2rem;
    color: var(--text-color-secondary);
    margin-top: 20px;
}

/* Badge List Container */
.badge-list {
    width: 100%;
    max-height: 300px; /* Limit height with scroll if many badges */
    overflow-y: auto; /* Enable vertical scrolling if needed */
    padding: 10px;
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin: 15px 0; /* Space above and below the list */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* Individual Badge Item */
.badge-item {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space checkbox and label nicely */
    padding: 12px 15px;
    margin-bottom: 8px;
    background-color: var(--input-background);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    cursor: pointer; /* Make the whole item clickable */
}

/* Hover Effect for Badge Item */
.badge-item:hover {
    background-color: var(--hover-background);
    transform: translateX(5px); /* Slight slide effect */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
}

/* Custom Checkbox Styling for Badges */
.badge-item input[type="checkbox"] {
    position: relative; /* Reset from absolute for inline use */
    opacity: 1; /* Make visible */
    width: 20px;
    height: 20px;
    margin-right: 15px; /* Space between checkbox and label */
    cursor: pointer;
    accent-color: var(--link-color); /* Modern checkbox color */
}

/* Badge Label Styling */
.badge-item label {
    flex-grow: 1; /* Take up remaining space */
    font-size: 1rem;
    color: var(--text-color);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px; /* Space between icon and text */
    cursor: pointer;
    user-select: none; /* Prevent text selection */
}

/* Badge Icon Styling */
.badge-item label::before {
    content: attr(data-icon); /* Use data-icon attribute for dynamic icons */
    font-size: 1.2rem; /* Size of the badge icon */
    color: var(--link-color);
}

/* Checked State Styling */
.badge-item input[type="checkbox"]:checked + label {
    color: var(--link-color); /* Highlight text when checked */
    font-weight: bold;
}

/* General Mobile Reset */
@media (max-width: 768px) {
    body, html {
        margin: 0;
        padding: 0;
        overflow-x: hidden; /* Prevent horizontal scrolling */
        font-size: 14px; /* Adjust base font size for mobile */
    }

    .users-per-page-selector {
        flex-direction: column;
        align-items: stretch;
        gap: 15px;
    }

    .users-per-page-selector input[type="number"],
    .users-per-page-selector select {
        width: 100%;
    }

    /* Layout Adjustments */
    .adminpanel-layout {
        flex-direction: column; /* Stack sidebar and content */
        min-height: auto; /* Remove forced height */
        background-color: var(--main-background);
    }

    .adminpanel-container {
        width: 100%;
        padding: 10px; /* Adjust padding for mobile */
        box-sizing: border-box;
    }

    .adminpanel-section {
        padding: 20px; /* Adjust section padding */
        margin-bottom: 20px; /* Reduce spacing between sections */
    }

    .adminpanel-card {
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 6px; /* Smaller border radius */
        font-size: 0.9rem; /* Reduce font size */
    }

    /* Sidebar Adjustments */
    .adminpanel-sidebar {
        width: 100%; /* Full width */
        padding: 10px;
        position: static; /* Remove fixed positioning */
        box-shadow: none; /* Remove shadow */
        background: linear-gradient(135deg, #2f1235, #313c52);
    }

    .sidebar-tabs {
        display: flex;
        flex-wrap: wrap; /* Allow items to wrap */
        justify-content: center;
        gap: 8px; /* Space between items */
    }

    .sidebar-tab {
        padding: 8px 10px;
        font-size: 0.8rem; /* Reduce text size */
        border-radius: 4px; /* Smaller border radius */
        text-align: center; /* Center-align text */
    }

    /* Pagination and Buttons */
    .pagination-container,
    .pagination-controls {
        flex-direction: column; /* Stack pagination elements */
        gap: 10px; /* Space between items */
    }

    .pagination-button {
        padding: 8px 12px;
        font-size: 0.9rem; /* Adjust font size */
    }

    .switch-button {
        padding: 10px;
        font-size: 0.9rem; /* Reduce font size */
    }

    /* User List Adjustments */
    .user-list-container {
        display: flex;
        flex-direction: column; /* Stack items */
        gap: 15px; /* Add spacing */
    }

    .user-card {
        padding: 15px;
        font-size: 0.9rem; /* Reduce font size */
    }

    .user-avatar {
        width: 60px;
        height: 60px; /* Adjust avatar size */
    }

    /* Forms and Inputs */
    .upload-input,
    .category-select {
        width: 100%; /* Full width */
        font-size: 0.9rem; /* Adjust font size */
        padding: 10px; /* Reduce padding */
    }

    .search-button,
    .add-ip-button,
    .resolve-reports-button {
        padding: 10px;
        font-size: 0.9rem; /* Reduce button size */
    }

    .adminpanel-success,
    .adminpanel-error {
        padding: 10px;
        font-size: 0.9rem; /* Adjust alert size */
    }

    /* IP History Section */
    .ip-history {
        padding: 10px;
        font-size: 0.8rem; /* Smaller font size */
    }

    /* Dropdown Menu */
    .dropdown-menu {
        width: 100%; /* Full width on mobile */
        position: static; /* Remove absolute positioning */
        box-shadow: none; /* Remove shadow */
    }

    .dropdown-item {
        padding: 8px; /* Adjust padding */
        font-size: 0.9rem; /* Adjust font size */
    }

    /* Charts and Analytics */
    .analytics-chart-container {
        width: 100%; /* Full width */
        height: auto; /* Maintain aspect ratio */
    }

    .analytics-summary-number {
        font-size: 1.2rem; /* Adjust font size */
    }

    /* Maintenance Mode */
    .maintenance-message {
        font-size: 0.9rem; /* Reduce text size */
        padding: 10px;
    }

    /* Game Section */
    .game-section {
        padding: 15px; /* Adjust padding */
        margin-bottom: 15px; /* Reduce spacing */
    }

    .game-link {
        font-size: 0.9rem; /* Adjust font size */
    }

    /* Voter List */
    .voter-list {
        padding: 10px;
        font-size: 0.9rem; /* Adjust font size */
    }

    .voter-list li {
        padding: 8px; /* Adjust padding */
        font-size: 0.8rem; /* Smaller text */
    }

    /* Modal Adjustments */
    .confirmation-modal {
        padding: 10px;
        font-size: 0.9rem; /* Adjust modal size */
    }

    .confirm-button,
    .cancel-button {
        padding: 10px;
        font-size: 0.9rem; /* Reduce button size */
    }
    .badge-list {
        max-height: 200px; /* Reduce height on mobile */
        padding: 8px;
    }

    .badge-item {
        padding: 10px 12px; /* Slightly smaller padding */
        margin-bottom: 6px;
    }

    .badge-item input[type="checkbox"] {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    .badge-item label {
        font-size: 0.9rem; /* Slightly smaller text */
    }

    .badge-item label::before {
        font-size: 1rem; /* Adjust icon size */
    }
}


/* Fade-in Animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }


}
